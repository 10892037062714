import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Table/Table.module.scss';

const TableData = ({ type = 'td', children, className }) => {
  const cl = classNames(styles.tableData, className);
  return type === 'td' ? (
    <td className={cl}>{children}</td>
  ) : (
    <th className={cl}>{children}</th>
  );
};

const TableRow = ({ className, children }) => {
  return <tr className={className}>{children}</tr>;
};

const Table = ({ children, className }) => {
  return (
    <div>
      <table
        className={classNames('table table-bordered', styles.table, className)}
      >
        {children}
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Table.defaultProps = {
  className: '',
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TableRow.defaultProps = {
  className: '',
};

TableData.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
};

TableData.defaultProps = {
  children: null,
  className: '',
  type: 'td',
};

export { Table as default, TableRow, TableData };
