import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Badge/Badge.module.scss';

const Badge = ({ children, className, theme }) => (
  <span
    className={classNames(
      'badge',
      `badge-${theme}`,
      className,
      styles[theme],
      styles.badge,
      'd-flex align-items-center justify-content-center',
      'text-uppercase',
    )}
  >
    {children}
  </span>
);

const RemovableBadge = ({ children, className }) => (
  <span
    className={classNames(
      'badge badge-pill',
      className,
      styles.removableBadge,
      'd-flex align-items-center justify-content-center',
    )}
  >
    <span
      className={classNames(styles.removableBadgeChildren, 'text-truncate')}
    >
      {children}
    </span>
    <span className={classNames('icon X mr-2', styles.removableBadgeIcon)} />
  </span>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
  theme: 'primary',
};

export { Badge as default, RemovableBadge };
